import axios from "axios";
import { globvar } from "@/store/state.module";
const API_URL = process.env.VUE_APP_BASE_URL + "dashboard/";
class AuthService {
  login(user) {
    return axios
      .post(API_URL + "auth/login", {
        email: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data?.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
          globvar.state.fullname = response.data.data.fullname;
        }

        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  verifyOTP(payload) {
    return axios
      .post(API_URL + "auth/totp-validation", {
        email: payload.email,
        validation_code: payload.validation_code,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          globvar.state.fullname = response.data.data.fullname;
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  forceChangePassword(payload) {
    return axios
      .post(API_URL + "auth/change-password", {
        email: payload.email,
        password: payload.password,
        confirm_password: payload.confirm_password,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  logout() {
    localStorage.removeItem("user");
  }
  register(user) {
    return axios.post(API_URL + "register", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }
}

export default new AuthService();
