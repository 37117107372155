import AuthService from "@/services/auth.service";
// const user = JSON.parse(localStorage.getItem("user"));
// const initialState = user
//   ? { status: { loggedIn: true }, user }
//   : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: {
    status: {
      loggedIn: false,
    },
    user: null,
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    verifyOTP({ commit }, user) {
      return AuthService.verifyOTP(user).then(
        (user) => {
          commit("verifySuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("verifyFailure");
          return Promise.reject(error);
        }
      );
    },
    forceChangePassword({ commit }, user) {
      return AuthService.forceChangePassword(user).then(
        (user) => {
          commit("forceChangePasswordSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("forceChangePasswordFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    verifySuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    verifyFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    // forceChangePasswordSuccess(state) {
    //   state.status.loggedIn = false;
    //   state.user = null;
    // },
    // forceChangePasswordFailure(state) {
    //   state.status.loggedIn = false;
    //   state.user = null;
    // },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
};
