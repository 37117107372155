<template>
  <main class="main-content">
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <ToppayNotification :menu="'payout'"></ToppayNotification>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Data {{ currentRouteName }}</h6>
            </div>
            <div class="card">
              <!-- <v-expand-transition> 
                <div class="card-body p-3" v-show="expand">
                  <TransactionChart :loading="loading" :chartdata=chartData chartTitle="Payout Trx"></TransactionChart>
                </div>
              </v-expand-transition> -->
              <div class="card-body p-3">
                <TableFilter
                  f_action="payout"
                  :f_loading="loading"
                  :f_submit="searchSubmit"
                  :f_download="true"
                  :f_partner_code="true"
                  :f_amount="true"
                  :f_date_range="true"
                  :f_refno="true"
                  :f_sourcerefno="true"
                  :f_destination="true"
                  :f_status="true"
                  :f_channel="true"
                  :can_download="true"
                  :can_show_chart="true"
                  @toggle-expand="toggleExpandListener"
                  :expand="expand"
                  :f_submitdownload="downloadSubmit"
                  :holidayInfo="false"
                ></TableFilter>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <ShapeInfo
                    :customCols="'col'"
                    :loading="loading"
                    title="Daily Topup Amount"
                    :value="totalTopupAmount"
                    :imgsrc="require('@/assets/img/idr-icon.png')"
                  ></ShapeInfo>
                  <ShapeInfo
                    :customCols="'col'"
                    :loading="loading"
                    :isReadyToSettle="true"
                    title="Remaining Balance"
                    :value="currentBalance"
                    :imgsrc="require('@/assets/img/idr-icon.png')"
                  ></ShapeInfo>
                  <ShapeInfo
                    :customCols="'col'"
                    :loading="loading"
                    title="Accumulated Topup Amount"
                    :value="accumulatedTopupAmount"
                    :subvalue="subvalue"
                    :imgsrc="require('@/assets/img/idr-icon.png')"
                  ></ShapeInfo>
                </div>
                <div class="row mt-4">
                  <a
                    :href="filedownload"
                    v-if="filedownload"
                    class="btn btn-behance mr-2"
                    type="button"
                    ><i class="fa fa-solid fa-file-excel"></i> Dowload</a
                  >
                  <EasyDataTable
                    show-index
                    buttons-pagination
                    alternating
                    v-model:server-options="serverOptions"
                    :server-items-length="numberOfPages"
                    :loading="loading"
                    :headers="headers"
                    :items="payouttransactions"
                    :search-field="searchField"
                    :search-value="searchValue"
                  >
                    <template #expand="item">
                      <div style="padding: 15px">
                        {{ item.product_name }} settlement
                        {{ item.settlement_time }} days
                      </div>
                    </template>
                    <template #item-trx_status="item">
                      <span
                        v-if="item.trx_status == '00'"
                        class="badge bg-success text-white text-bold"
                        >{{ item.trx_status }}</span
                      >
                      <span
                        v-else-if="item.trx_status == '96'"
                        class="badge bg-error text-white text-bold"
                        >{{ item.trx_status }}</span
                      >
                      <span
                        v-else
                        class="badge bg-warning text-white text-bold"
                        >{{ item.trx_status }}</span
                      >
                    </template>
                    <template #item-amount="{ amount }">
                      <span class="text-bold">{{ amount }}</span>
                    </template>
                    <template #loading>
                      <img
                        :src="imageLoading"
                        style="width: 100px; height: 80px"
                      />
                    </template>
                  </EasyDataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RequestService from "@/services/request.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
// import TransactionChart from  "@/components/TransactionChart";
import e2payApis from "@/services/e2pay-apis";
import ShapeInfo from "@/components/ShapeInfo";
import ToppayNotification from "@/components/ToppayNotification";

export default {
  name: "TablePayout",
  props: {
    model: String,
  },
  components: {
    NavigationTop,
    TableFilter,
    ShapeInfo,
    ToppayNotification,
    // TransactionChart
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    toggleExpandListener() {
      this.expand = !this.expand;
    },
    downloadSubmit(downloadtype, dataParam) {
      this.loading = true;
      console.log("downloadtype", downloadtype);
      this.searchParam = dataParam;
      this.searchParam.download_type = downloadtype;
      this.searchParam.download = true;
      this.searchParam.trxType = "payout";
      this.searchParam.serverOptions = this.serverOptions;
      RequestService.postRequest(
        e2payApis.GET_LINK_DOWNLOAD,
        this.searchParam
      ).then((response) => {
        console.log("response", response);
        this.loading = false;
        window.open(response.data.data.downloadLink, "_blank");
      });
    },
    searchSubmit(dataParam) {
      this.searchParam = dataParam;
      this.searchParam.trxType = "payout";
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.searchParam.trxType = "payout";
      this.searchParam.chartType = "payout";
      this.searchParam.serverOptions = this.serverOptions;
      RequestService.postRequest(e2payApis.GET_TRX, this.searchParam).then(
        (response) => {
          this.payouttransactions = response.data.data.data;
          this.numberOfPages = response.data.data.dataCount;
          this.loading = false;
          this.filedownload = response.data.data.downloadLink || "";
          // if(!this.filedownload){
          //   this.downloadSubmit();
          // }
        }
      );
      RequestService.postRequest(
        e2payApis.GET_PAYOUT_CHART_DATA,
        this.searchParam
      ).then((response) => {
        this.chartData = response.data.data.chart1;
        this.loading = false;
      });
      RequestService.postRequest(
        e2payApis.GET_BALANCE_HISTORY,
        this.searchParam
      ).then((response) => {
        this.totalTopupAmount = response.data.data.totalTopupAmount;
        this.accumulatedTopupAmount = response.data.data.accumulatedTopupAmount;
        this.currentBalance = response.data.data.currentBalance;
        this.loading = false;
      });
    },
  },
  data: () => ({
    filedownload: "",
    imageLoading: require("@/assets/img/cube_loading.gif"),
    numberOfPages: 0,
    payouttransactions: [],
    content: "",
    searchField: undefined,
    searchValue: "",
    loading: false,
    totalTopupAmount: "0",
    currentBalance: "0",
    accumulatedTopupAmount: "0",
    subvalue: "Until MM-YYYY",
    headers: [
      { text: "Trx Date", value: "trx_datetime" },
      { text: "RC", value: "trx_status" },
      { text: "Status", value: "sts_desc", sortable: true },
      { text: "Merchant Code", value: "partner_code" },
      { text: "Merchant Name", value: "partner_name" },
      { text: "RefNo", value: "refNo" },
      { text: "SourceRefNo", value: "source_refno" },
      { text: "Destination", value: "customerId" },
      { text: "Type", value: "service_name" },
      { text: "Gross Amount (Rp.)", value: "netamount" },
      { text: "Fee (Rp.)", value: "fee" },
      { text: "Net Amount (Rp.)", value: "amount" },
      { text: "Channel", value: "payment_id" },
      { text: "Desc", value: "product_name" },
    ],
    searchParam: {},
    serverOptions: {
      page: 1,
      rowsPerPage: 25,
      sortBy: "",
      sortType: "",
    },
    expand: false,
    chartData: {
      labels: [
        "2023-08-01",
        "2023-08-09",
        "2023-08-10",
        "2023-08-19",
        "2023-08-21",
      ],
      datasets: [
        {
          label: "Payout",
          backgroundColor: "#a3f879",
          borderColor: "#a3f879",
          data: [0, 1, 3, 0, 0],
          tension: 0.1,
        },
      ],
    },
  }),
  mounted() {
    this.fetchData();
    const today = new Date();
    this.subvalue =
      "Until " +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      today.getFullYear();
  },
  watch: {
    serverOptions: {
      handler() {
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true,
    },
    searchParam: function (newVal) {
      const today = new Date(newVal.filter_date_range_end);
      this.subvalue =
        "Until " +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "-" +
        today.getFullYear();
    },
  },
};
</script>

<style scoped></style>
